<template>
    <v-card
        v-if="currentEvents.length === 1 && currentEvents[0].active"
        class="white--text event-img"
        @click="redirectTo(currentEvents[0].link)"
    >
        <v-img contain :src="currentEvents[0].imgUrl" min-width="100px" min-height="120px" />
    </v-card>
    <div class="events-carousel" v-else-if="currentEvents">
        <v-carousel
            v-model="currentBanner"
            hide-delimiter-background
            cycle
            height="auto"
            class="rounded-xl"
        >
            <v-carousel-item v-for="event in currentEvents" :key="event.id">
                <v-card
                    v-if="event.active"
                    class="white--text event-img"
                    @click="redirectTo(event.link)"
                >
                    <v-img contain :src="event.imgUrl" min-width="100px" min-height="120px" />
                </v-card>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Events',
    props: {
        section: String,
    },
    data() {
        return {
            currentBanner: 0,
        }
    },
    computed: {
        ...mapState({
            marketingParams: (state) => state.marketingParams,
        }),
        currentEvents() {
            const sectionEvents = this.marketingParams.filter(
                (param) => param.section === this.section
            )

            if (!sectionEvents.length) return []

            return sectionEvents.map((event) => {
                const { id, imgUrl, link, active } = event
                return {
                    id,
                    imgUrl: imgUrl[this.$i18n.locale].lg,
                    link,
                    active,
                }
            })
        },
    },
    methods: {
        redirectTo(link) {
            if (link) window.open(link, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.event-img {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    background: transparent;
    &:hover {
        opacity: 0.8;
    }
}
.events-carousel{
    position: relative;
    z-index: 0;
    &::after{
        content: '';
        border-radius: 1.5em;
        box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 20px);
        z-index: -1;
    }
    ::v-deep .v-carousel__controls{
        height: 20px;
        left: 50%!important;
        transform: translateX(-50%);
        width: auto;
    }
    ::v-deep .v-btn--icon.v-size--small,
    ::v-deep .v-btn--icon.v-size--small .v-icon,
    ::v-deep .v-btn--fab.v-size--small .v-icon{
        height: 10px;
        width: 10px;
        font-size: 8px!important;
    }
}
</style>
