import Services from './base-services'

const service = new Services('quickStats')
import mapParameters from '../utils/map-parameters'

export default {
    getData(teacherId: number) {
        const params = mapParameters({
            teacherId: teacherId ? teacherId : null,
        })
        return service.get('getData', params, true)
    },
}
