<template>
    <v-row>
        <v-col cols="12" class="pa-0">
            <v-tabs
                v-model="range"
                class="mb-10"
                height="40"
                slider-size="6"
                slider-color="#514773"
                @change="newRange"
            >
                <v-tab
                    v-for="item in items"
                    :key="item"
                    class="no-uppercase text-subtitle-1 tab-items text--left"
                    active-class="font-weight-black black--text"
                >
                    {{ item }}
                </v-tab>
            </v-tabs>
        </v-col>
        <v-col cols="12" md="6" class="py-0 px-3 px-xl-6">
            <v-card elevation="8" class="selected pointer" id="elem_active_users"
                @click="newData(0)" :loading="loading"
            >
                <v-row>
                    <v-col v-resize="onResize" id="activeUsers" cols="12"
                        class="graphs mt-4 mt-lg-0" v-show="chartsReady"
                    />
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="py-0 px-3 px-xl-6">
            <v-card elevation="8" class="no-selected pointer" id="elem_reading_hours"
                @click="newData(1)" :loading="loading"
            >
                <v-row>
                    <v-col v-resize="onResize" id="readingHours" cols="12"
                        class="graphs mt-4 mt-lg-0" v-show="chartsReady"
                    />
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="py-6 px-3 px-xl-6">
            <v-card elevation="8" class="no-selected pointer" id="elem_read_books"
                @click="newData(2)" :loading="loading"
            >
                <v-row>
                    <v-col v-resize="onResize" id="readBooks" cols="12"
                        class="graphs mt-4 mt-lg-0" v-show="chartsReady"
                    />
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" class="py-6 px-3 px-xl-6">
            <v-card elevation="8" class="no-selected pointer" id="elem_average_quiz_scores"
                @click="newData(3)" :loading="loading"
            >
                <v-row>
                    <v-col v-resize="onResize" id="averageQuizScores" cols="12"
                        class="graphs mt-4 mt-lg-0" v-show="chartsReady"
                    />
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
const echarts = require('echarts')

const dataZero = [0, 0, 0, 0, 0, 0, 0]

export default {
    props: {
        loading: { type: Boolean, default: true },
        quickStats: { type: Array, default: () => [] },
    },
    data() {
        return {
            chartActiveUsers: {},
            chartAverageQuizScores: {},
            chartReadBooks: {},
            chartReadingHours: {},
            data: 0,
            elementActiveUsers: {},
            elementAverageQuizScores: {},
            elementReadBooks: {},
            elementReadingHours: {},
            chartsReady: false,
            dataActiveUsers: dataZero,
            dataAverageQuizScores: dataZero,
            dataReadBooks: dataZero,
            dataReadingHours: dataZero,
            valueActiveUsers: 0,
            valueAverageQuizScores: 0,
            valueReadBooks: 0,
            valueReadingHours: 0,
            previousElement: 0,
            range: 2,
        }
    },
    mounted() {
        this.chartActiveUsers = echarts.init(document.getElementById('activeUsers'))
        this.chartAverageQuizScores = echarts.init(document.getElementById('averageQuizScores'))
        this.chartReadBooks = echarts.init(document.getElementById('readBooks'))
        this.chartReadingHours = echarts.init(document.getElementById('readingHours'))
        this.changeRank()
        this.setElements()
        this.chartsReady = true
    },
    computed: {
        items() {
            return [
                this.$t('stats.seven_days'),
                this.$t('stats.thirty_days'),
                this.$t('stats.ninety_days'),
            ]
        },
    },
    methods: {
        changeRank() {
            this.setActiveUsersChart()
            this.setAverageQuizScoresChart()
            this.setReadingHoursChart()
            this.setReadBooksChart()
        },
        getSubtextSize() {
            const textSizes = {
                xs: 12,
                sm: 12,
                md: 11,
                lg: 9,
                xl: 14,
            }
            return textSizes[this.$vuetify.breakpoint.name]
        },
        getOption(serieData) {
            return {
                title: {
                    text: `{icon|}{text| ${serieData.number}}${serieData.percent ? '{percent|%}' : ''}`,
                    subtext: `{subtext| ${serieData.title}}`,
                    textStyle: {
                        rich: {
                            icon: {
                                height: 30,
                                backgroundColor: {
                                    image: serieData.icon,
                                },
                            },
                            text: {
                                align: 'left',
                                color: 'black',
                                fontSize: 30,
                                fontWeight: 'bold',
                            },
                            percent: {
                                verticalAlign: 'top',
                                color: 'black',
                                fontSize: 18,
                                fontWeight: 'bold',
                            },
                        },
                    },
                    subtextStyle: {
                        rich: {
                            subtext: {
                                align: 'left',
                                fontSize: this.getSubtextSize(),
                            },
                        },
                    },
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    top: '50%',
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                },
                yAxis: {
                    type: 'value',
                    show: false,
                },
                series: [serieData.data],
            }
        },
        getSerieData(graph) {
            let areaColor = '#FEEDDE'
            let lineColor = '#F9C456'
            let dataS = this.dataAverageQuizScores
            let title = this.$t('stats.stats[3]')
            let icon = '/img/teacher/quick-stats/avg-quiz-score.svg'
            let percent = true
            let number = this.valueAverageQuizScores

            switch (graph) {
                case 0:
                    areaColor = '#E2DAFE'
                    lineColor = '#6B45F9'
                    dataS = this.dataActiveUsers
                    title = this.$t('stats.stats[0]')
                    icon = '/img/teacher/quick-stats/active-users.svg'
                    number = this.valueActiveUsers
                    break

                case 1:
                    areaColor = '#DFF8F4'
                    lineColor = '#75D7E9'
                    dataS = this.dataReadingHours
                    title = this.$t('stats.stats[1]')
                    icon = '/img/teacher/quick-stats/reading-hours.svg'
                    percent = false
                    number = this.valueReadingHours
                    break

                case 2:
                    areaColor = '#D4FFF7'
                    lineColor = '#25E1D3'
                    dataS = this.dataReadBooks
                    title = this.$t('stats.stats[2]')
                    icon = '/img/teacher/quick-stats/reads.svg'
                    percent = false
                    number = this.valueReadBooks
                    break
                default:
                    break
            }
            return {
                data: {
                    data: dataS,
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0, color: areaColor,
                                },
                                {
                                    offset: 0.75, color: areaColor,
                                },
                                {
                                    offset: 1, color: 'white',
                                },
                            ],
                            global: false,
                        },
                    },
                    lineStyle: {
                        color: lineColor,
                        width: 5,
                    },
                },
                title,
                icon,
                percent,
                number,
            }
        },
        mapCountPercent(array, isCount) {
            const result = []
            if (isCount) {
                array.forEach((element) => {
                    result.push(element.count)
                });
            } else {
                array.forEach((element) => {
                    result.push(element.percent)
                });
            }
            return result
        },
        newRange(data) {
            this.$emit('newRange', data)
            this.range = data
            this.reloadData()
        },
        onResize() {
            if (this.chartsReady) {
                this.chartActiveUsers.resize()
                this.chartAverageQuizScores.resize()
                this.chartReadBooks.resize()
                this.chartReadingHours.resize()
            }
        },
        reloadData() {
            if (this.quickStats && this.quickStats.length > 0) {
                const data = this.quickStats[this.range]
                this.valueActiveUsers = data.activeUsers
                this.valueAverageQuizScores = data.avgQuizScore
                this.valueReadBooks = data.reads
                this.valueReadingHours = data.readingHours
                this.dataActiveUsers = this.mapCountPercent(data.activeReadersInterval, false)
                this.dataAverageQuizScores = this.mapCountPercent(data.avgQuizInterval, false)
                this.dataReadBooks = this.mapCountPercent(data.readsInterval, true)
                this.dataReadingHours = this.mapCountPercent(data.readingHoursInterval, true)
            }
            this.changeRank()
        },
        newData(data) {
            switch (this.previousElement) {
                case 0:
                    this.elementActiveUsers.classList.remove('selected')
                    this.elementActiveUsers.classList.add('no-selected')
                    break;
                case 1:
                    this.elementReadingHours.classList.remove('selected')
                    this.elementReadingHours.classList.add('no-selected')
                    break;

                case 2:
                    this.elementReadBooks.classList.remove('selected')
                    this.elementReadBooks.classList.add('no-selected')
                    break;
                case 3:
                    this.elementAverageQuizScores.classList.remove('selected')
                    this.elementAverageQuizScores.classList.add('no-selected')
                    break;
                default:
                break;
            }
            switch (data) {
                case 0:
                    this.elementActiveUsers.classList.remove('no-selected')
                    this.elementActiveUsers.classList.add('selected')
                    break;
                case 1:
                    this.elementReadingHours.classList.remove('no-selected')
                    this.elementReadingHours.classList.add('selected')
                    break;

                case 2:
                    this.elementReadBooks.classList.remove('no-selected')
                    this.elementReadBooks.classList.add('selected')
                    break;
                case 3:
                    this.elementAverageQuizScores.classList.remove('no-selected')
                    this.elementAverageQuizScores.classList.add('selected')
                    break;
                default:
                break;
            }
            this.$emit('newData', data)
            this.data = data
            this.previousElement = data
            this.reloadData()
        },
        setActiveUsersChart() {
            this.chartActiveUsers.setOption(this.getOption(this.getSerieData(0)))
            setTimeout(() => {
                this.onResize()
            }, 10)
        },
        setAverageQuizScoresChart() {
            this.chartAverageQuizScores.setOption(this.getOption(this.getSerieData(3)))
            setTimeout(() => {
                this.onResize()
            }, 10)
        },
        setReadBooksChart() {
            this.chartReadBooks.setOption(this.getOption(this.getSerieData(2)))
            setTimeout(() => {
                this.onResize()
            }, 10)
        },
        setReadingHoursChart() {
            this.chartReadingHours.setOption(this.getOption(this.getSerieData(1)))
            setTimeout(() => {
                this.onResize()
            }, 10)
        },
        setElements() {
            this.elementActiveUsers = document.getElementById('elem_active_users')
            this.elementAverageQuizScores = document.getElementById('elem_average_quiz_scores')
            this.elementReadBooks = document.getElementById('elem_read_books')
            this.elementReadingHours = document.getElementById('elem_reading_hours')
        },
    },
    watch: {
        quickStats() {
            this.reloadData()
        },
    },
}
</script>

<style scoped>
.no-uppercase {
     text-transform: none;
}
.graphs {
    width: 90px;
    height: 170px;
}
.pointer {
  cursor: pointer;
}
.no-selected {
    border: 5px solid transparent;
}
.selected {
    border: 5px solid #B9B1D5;
}
.v-tabs-bar {
    height: 80px  !important;
}
.v-tabs-bar__content {
    height: 80px  !important;
}
.v-tabs-slider-wrapper {
    height: 5px;
}
.tab-items {
    border-bottom: 6px solid #F1F1F1;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 40px;
}
</style>
