<template>
    <div class="w-100">
        <vue-headful :title="$t('title_quick_stats')" description="title" />
        <v-card elevation="12" class="py-10 px-8 my-9">
            <v-row>
                <h4 class="text-h4 px-2" style="font-weight: bolder">
                    {{ $t('stats.quick_stats') }}
                </h4>
            </v-row>
            <v-row>
                <v-col cols="12" lg="4">
                    <StudentStatistics
                        class="mt-0 mt-lg-6"
                        :loading="loading"
                        :quickStats="quickStats"
                        v-on:newData="setData"
                        v-on:newRange="setRange"
                    />

                </v-col>
                <v-col cols="12" lg="8">
                    <Events section="home" class="mt-0 mt-lg-6" />
                    <UsersActivity
                        class="mt-4 mt-lg-2 mt-xl-4"
                        :loading="loading"
                        :quickStats="quickStats"
                        :data="dataSelected"
                        :range="rangeSelected"
                    />
                </v-col>
            </v-row>
        </v-card>

        <transition name="slide-fade">
            <v-row class="floating-survey px-3 py-3" v-if="currentSurvey">
                <SurveyContainer 
                :title="currentSurvey.title"
                @on-close="handlingCloseSurvey"
                />
            </v-row>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Events from '@/components/stats/Events.vue'
import UsersActivity from '@/components/stats/UsersActivity.vue'
import StudentStatistics from '@/components/stats/StudentStatistics.vue'
import SurveyContainer from '@/components/core/surveys/container'
import QuickStatsService from '../../services/quickStats'

export default {
    name: 'QuickStats',
    components: {
        Events,
        UsersActivity,
        StudentStatistics,
        SurveyContainer
    },
    data() {
        return {
            dataSelected: 0,
            loading: true,
            quickStats: null,
            rangeSelected: 2,
        }
    },
    computed: {
        ...mapState({
            teacherId: (state) => state.userData.user.id,
            currentSurvey: (state) => state.survey.currentSurvey,
        }),
    },
   
    methods: {
        ...mapMutations('survey', ['resetState']),
        ...mapActions('survey', ['getTeacherCurrentSurvey']),
        getData() {
            QuickStatsService.getData(this.teacherId)
                .then((response) => {
                    this.loading = false
                    this.quickStats = response.content
                })
                .catch((error) => console.error(error))
        },
        setData(data) {
            this.dataSelected = data
        },
        setRange(range) {
            this.rangeSelected = range
        },
        handlingCloseSurvey() {
            this.resetState()
        }
    },
    async created() {
        try {
            // Validate if is local environemnt
            if (process.env.NODE_ENV !== 'development') {
                await this.getTeacherCurrentSurvey({ teacherID: this.teacherId })
            }
        } catch (error) {
            // TODO: Handling error of getting survey
        }
        await this.getData()
    }
}
</script>

<style scoped>
.floating-survey {
    position: fixed;
    bottom: 21px;
    right: 21px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s ease-in-out;
}

.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateX(100%);
}

.slide-fade-enter-to {
  transform: translateX(0);
}

.slide-fade-leave-active {
  transform: translateX(100%);
}
</style>
